import React from 'react'
import EshopNaMieruTemplate from '../components/pages/eshopNaMieru/EshopNaMieruTemplate'
import { graphql } from 'gatsby'


const EshopNaMieru = ({data}) => (
  <EshopNaMieruTemplate data={data} lang={'sk'}/>
)


export const query = graphql`{
  eshopNaMieru: file(relativePath: {eq: "pages/sk/eshop-na-mieru/eshop_na_mieru.md"}) {
    childMarkdownRemark {
      frontmatter {
        seo_title
        seo_description
        title
        subtitle
        description
        caseStudieTitle
        caseStudieSubtitle
        eshopTitle
        eshobSubtitle
        eshopButton
        eshopButtonLink
        offer {
          title
          description
          icon {
            publicURL
          }
        }
        benefits {
          title
          description
          list {
            title
            description
            icon {
              publicURL
            }
          }
        }
        editor {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          list {
            title
            description
            icon {
              publicURL
            }
          }
        }
        process {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 700, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          list {
            title
            description
          }
        }
        functions {
          title
          menu {
            menu_item
            title
            description
            list {
              title
              description
              icon {
                publicURL
              }
            }
          }
        }
      }
    } 
  }
  
  eshops: file(relativePath: {eq: "pages/sk/references/web_pages_eshop.md"}) {
    childMarkdownRemark {
        frontmatter {
            other_projects {
                title
                url_override
                show_in_references_slider
                url
                thumbnail {
                    childImageSharp {
                        fluid(maxWidth: 700, quality: 75) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                case_study
            }
        }
    }
  }
  caseStudies: file(relativePath: {eq: "pages/sk/homepage/featured_case_studies.md"}) {
      childMarkdownRemark {
          frontmatter {
              case_studies {
                  title
                  background_color
                  case_study
                  description
                  category
                  thumbnail_layers_cs_picker {
                      thumbnail {
                          childImageSharp {
                              fixed(width: 191, height: 124, quality: 75) {
                                  ...GatsbyImageSharpFixed_withWebp
                              }
                          }
                      }
                  }
                  thumbnail_layers {
                      thumbnail {
                          childImageSharp {
                              fluid(maxWidth: 700, quality: 75) {
                                  ...GatsbyImageSharpFluid_withWebp
                              }
                          }
                      }
                  }
              }
          }
      }
  }
  saidAboutUs: file(relativePath: {eq: "pages/sk/homepage/said_about_us.md"}) {
    childMarkdownRemark {
        frontmatter {
            text_paragraph
            references {
                description
                logo: logo {
                    childImageSharp {
                        fluid(maxWidth: 200) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                logoBig: logo {
                    childImageSharp {
                        fluid(maxWidth: 200) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                author
            }
        }
    }
  }
  blogs: allMarkdownRemark(
    filter: {
      fileAbsolutePath: {regex: "/posts/blog/"}
      frontmatter: { category: { eq: "E-shopy" } }
    }
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          title
          perex
          category
          thumbnail {
              childImageSharp {
                  fluid(maxWidth: 380) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
          category
          
        }
      }
    }
  }
}
`

export default EshopNaMieru
