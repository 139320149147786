import React, { useState } from 'react'
import Layout from '../../layout'
import { getTranslateFunction, scrollToTarget } from '../../../utilities'
import Reveal from '../../reveal'
import * as styles from "./Styles.module.scss"
import LinkBtn from '../../linkBtn/LinkBtn'
import { TranslatedLink } from '../../TranslatedLink'
import HoverBtn from '../../HoverBtn'
import Img from 'gatsby-image'
import SeoHelmet from '../../seoHelmet'
import BlockSwitcher from '../../blockSwitcher/BlockSwitcher'
import CaseStudiesOtherProjects from '../../case-studies/caseStudiesOtherProjects'
import CaseStudiesPicker from '../../case-studies/caseStudiesPicker'
import Testimonials from '../../testimonials/Testimonials'
import '../../../lib/react-alice-carousel/scss/alice-carousel.scss'
import AliceCarousel from 'react-alice-carousel';
import Blog from '../../blog/Blog'
import CancelDragClick from '../../cancelDragClick'
import snarkdown from 'snarkdown'
import { CSSTransition } from 'react-transition-group'
import ContaktUs from '../../contactUs/ContactUs'


const EshopNaMieruTemplate = ({ data, lang }) => {

  let eshopNaMieru = data.eshopNaMieru.childMarkdownRemark.frontmatter
  let caseStudies = data.caseStudies.childMarkdownRemark.frontmatter.case_studies
  let eshopCaseStudies = caseStudies.filter(item => item.category === 'web-eshop')
  let eshops = data.eshops.childMarkdownRemark.frontmatter.other_projects
  let blogs = data.blogs.edges
  let saidAboutUs = data.saidAboutUs.childMarkdownRemark.frontmatter
  const [selectedKey, setSelectedKey] = useState(0)

    const t = getTranslateFunction(lang)
    
    const scrollBotton = () => {
        scrollToTarget(document.getElementById('functions'), 600)
    }


  return (
    <Layout lang={lang} className={styles.mainCont}>
      <SeoHelmet
        description={eshopNaMieru.seo_description}
        title={eshopNaMieru.seo_title}
      />
      {/* header */}
      <div className='container'>
        <div className={`row ${styles.header}`}>
          <div className='col-md-6'>
            <h1 dangerouslySetInnerHTML={{ __html: eshopNaMieru.title }} />
            <h2>{eshopNaMieru.subtitle}</h2>
          </div>
          <div className='col-md-6 d-flex align-items-center'>
            <p dangerouslySetInnerHTML={{ __html: snarkdown(eshopNaMieru.description) }}></p>
          </div>
        </div>
        <video autoPlay={true} muted={true} loop={true} controls={false}>
          <source src={'/videos/Narative_anim_16-9.mp4'} type="video/mp4" />
        </video>
      </div>
      {/* Infograf */}
      <div className={`${styles.topSection} ${styles.sectionMarginTop}`}>
        <img src="/images/svg/nrtvs-bg.svg" alt="bg" className={styles.bg} />
        <section id='offer'>
          <div className='container'>
            <div className="row">
              <div className={`col-md-6`}>
                <h2 className={styles.title}>{eshopNaMieru.offer.title}</h2>
                <p className={styles.description}>{eshopNaMieru.offer.description}</p>
              </div>
              <div className={`col-md-6`}>
                <img className={styles.diagram} alt="diagram" src={eshopNaMieru.offer.icon.publicURL} />
              </div>
            </div>
          </div>
        </section>
        {/* benefit lists */}
        <section id='benefits' className={`${styles.sectionMarginTop}`}>
          <div className='container'>
            <div className={`row `}>
              <div className={`col-md-12`}>
                <h2 className={styles.title}>{eshopNaMieru.benefits.title}</h2>
                <p className={styles.subtitle}>{eshopNaMieru.benefits.description}</p>
              </div>
              <div className={`col-md-12`}>
                <div className={`${styles.benefitCont}`}>
                  {eshopNaMieru.benefits.list.map((item, key) =>
                    <div key={key} className={`${styles.benefit}`}>
                      <div className={styles.iconOut}>
                        <img alt="icon" src={item.icon.publicURL} />
                      </div>
                      <h4>{item.title}</h4>
                      <p className={styles.formatText} dangerouslySetInnerHTML={{ __html: snarkdown(item.description) }}></p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={`${styles.topSection} ${styles.sectionMarginTop}`}>
        <img src="/images/svg/nrtvs-bg.svg" alt="bg" className={`${styles.bg} ${styles.rotated}`} />
        <section id='editor' className={`row ${styles.container}`}>
          <div className='col-xl-10 push-xl-2'>
            <div className="row">
              <div className={`col-lg-4 ${styles.editor}`}>
                <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: eshopNaMieru.editor.title }} />
                <p className={styles.subtitle}>{eshopNaMieru.editor.description}</p>
                <ul className={styles.list}>
                  {eshopNaMieru.editor.list.map((item, key) =>
                    <li key={key} className='d-flex'>
                      <div className={styles.listIcon}>
                        <div className={styles.iconOut}>
                          <img alt="icon" src={item.icon.publicURL} />
                        </div>
                      </div>
                      <div>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
              <div className={`col-lg-8 ${styles.eshopMockup} `}>
                <Img alt={'diagram'} fluid={eshopNaMieru.editor.image.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </section >

        <section className={`hp-projects ${styles.sectionMarginTop + ' ' + styles.hpProjects}`}>
          <CaseStudiesPicker
            title={eshopNaMieru.caseStudieTitle}
            subtitle={eshopNaMieru.caseStudieSubtitle}
            studies={eshopCaseStudies}
            isRight={true}
            lang={lang}
            enableBg={false}
            loadLast={false}
          />
          <div className={`d-flex justify-content-center ${styles.fixTopSpace}`}>
            <HoverBtn size={1.25}>
              <TranslatedLink to='/kontakt'>Mám záujem o E-shop</TranslatedLink>
            </HoverBtn>
          </div>
        </section>
      </div>


      <div className={`${styles.topSection} ${styles.sectionMarginTop} ${styles.fixSectionTopSpace}`}>
        <img src="/images/svg/nrtvs-bg.svg" alt="bg" className={styles.bg} />
        <section id='process'>
          <div className='container'>
            <div className="row">
              <div className={`col-md-6 pr-2 pr-md-5`}>
                <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: eshopNaMieru.process.title }} />
                <p className={styles.description}>{eshopNaMieru.process.description}</p>
                <div className={styles.blockImage}>
                  <Img alt={'diagram'} fluid={eshopNaMieru.process.image.childImageSharp.fluid} />
                </div>
              </div>
              <div className={`col-md-6`}>
                <BlockSwitcher items={eshopNaMieru.process.list} speed={0.05} />
                <div className={styles.blockLink}>
                  <LinkBtn size={1.1}>
                    <TranslatedLink to='/o-nas/ako-pracujeme'>Pozrite náš kompletný proces práce</TranslatedLink>
                  </LinkBtn>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='functions' className={`${styles.sectionMarginTop} ${styles.functions}`}>
            <div className='container'>
                <h2 className={`${styles.title}`} dangerouslySetInnerHTML={{ __html: eshopNaMieru.functions.title }} />
                <div className="row mt-md-5 pt-md-4">
                    <div className={`col-md-3`}>
                        <ul className={styles.menu}>
                        {eshopNaMieru.functions.menu.map((item, key) =>
                            <li
                            onClick={$event => setSelectedKey(key)}
                            key={key}
                            className={`${key === selectedKey ? styles.active : ''} cp`}
                            >
                            {item.menu_item}
                            </li>
                        )}
                        </ul>
                    </div>
                    <div className='col-md-9 mt-5 mt-lg-0 fade-to-top'>
                        <div className='p-relative'>
                        { eshopNaMieru.functions.menu.map((item, key) => (
                            <CSSTransition
                            unmountOnExit
                            in={selectedKey === key}
                            timeout={300}
                            classNames={'fade-slow'}
                            >
                            <div className='row'>
                                <div className='col-12'>
                                <h2 className={`${styles.title}`}>{item.title}</h2>
                                <p className={`${styles.description} mb-0`}>{item.description}</p>
                                </div>
                                {item.list.map((item, key) =>
                                <div key={key} className={`col-md-4 ${styles.benefit}`}>
                                    <div className={styles.iconOut}>
                                    <img alt="icon" src={item.icon.publicURL} />
                                    </div>
                                    <h4>{item.title}</h4>
                                    <p>{item.description}</p>
                                </div>
                                )}
                            </div>
                            </CSSTransition>
                        ))}
                        </div>
                    </div>
                </div>
                <div className='d-md-none mt-4'>
                    <LinkBtn size={1.1} color="blue" handleClick={scrollBotton}>
                        <div>
                            Ďalšie funkcie
                        </div>
                    </LinkBtn>
                </div>
            </div>
        </section>
        <Reveal id={`eshop-other-projects`}>
          <section className={`${styles.sectionMarginTop}`}>
            <div className={`container ${styles.fullToRight}`}>
              <div className='row'>
                <div className={`col-12 ${styles.carouselTitle}`}>
                  <h2 className={`${styles.title}`}>{eshopNaMieru.eshopTitle}</h2>
                  <div className={`${styles.showAllButton} mb-0 mb-lg-5 pb-4`}>
                    <LinkBtn size={1.1}>
                      <TranslatedLink to={eshopNaMieru.eshopButtonLink}>{eshopNaMieru.eshopButton}</TranslatedLink>
                    </LinkBtn>
                  </div>
                </div>
                <div className='col-12'>
                  <CaseStudiesOtherProjects
                      enableTitle={false}
                      responsive={
                        {
                            0: { items: 2.5 },
                            1198: { items: 3.5 },
                            1920: { items: 3.5 },
                        }
                      }
                      projects={eshops}
                      category={'eshop'}
                    lang={lang}
                  />
                </div>
              </div>
            
            </div>
            <div className='container'>
              <div className={`col-12 d-flex justify-content-center my-5 pb-10 ${styles.buttonSpace}`}>
                  <HoverBtn size={1.25} >
                    <TranslatedLink to='/kontakt'>Mám záujem o E-shop</TranslatedLink>
                  </HoverBtn>
                </div>
            </div>
          </section>
        </Reveal>


        <Reveal otherClassName={`mt-5 pt-5 mb-0`}>
          <Testimonials
            enableSaidAboutUs={true}
            references={saidAboutUs.references}
            paragraph={saidAboutUs.text_paragraph}
          />
        </Reveal>

        <Reveal>
          <section className="hp-blog homepage-blog">
            <div className="container">
              <div className="row text-center">
                <div className="col-md-9 m-auto">
                  <h2>{t('Homepage.FeaturedBlogPosts.Title')}</h2>
                  <LinkBtn alignment={'center'}>
                    <TranslatedLink to="/en/blog">{t('Homepage.FeaturedBlogPosts.AllPosts')}</TranslatedLink>
                  </LinkBtn>

                </div>
              </div>
              <div className="homepage-blob-carousel">
                <AliceCarousel
                  duration={500}
                  mouseTracking
                  infinite
                  responsive={{
                    0: { items: 1 },
                    500: { items: 1.5 },
                    700: { items: 2 },
                    920: { items: 3 },
                  }}
                  autoPlay={false}
                  autoPlayInterval={15 * 1000}
                  disableButtonsControls={true}
                  items={blogs.map((post, i) => {
                    return (
                      <CancelDragClick
                        key={'CancelDragClick' + i}
                        threshold={1}>
                        <div className={'mt-5'}
                          onMouseDown={() => {
                            document.querySelectorAll('.homepage-blob-carousel').forEach((e) => {
                              e.classList.add('is-dragged')
                            })
                          }}
                          onMouseUp={(e) => {
                            document.querySelectorAll('.homepage-blob-carousel.is-dragged').forEach((e) => {
                              e.classList.remove('is-dragged')
                            })
                          }}
                        >
                          <Blog post={post.node.frontmatter} image={post.node.frontmatter.thumbnail.childImageSharp.fluid} to={post.node.fields.slug} />
                        </div>
                      </CancelDragClick>
                    )
                  })} />
              </div>
            </div>
          </section>
        </Reveal>
        
        <Reveal>
          <ContaktUs
            LeftSideTitle='category'
            LeftSideSubTitle='Tvorba web stránok'
            LeftSideLink="/tvorba-webov"
            linkRight="/en/contact"
            rightSecondLink="/en/brief"
          />
        </Reveal>
      </div>

    </Layout>
  )
}


export default EshopNaMieruTemplate



