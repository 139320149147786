// extracted by mini-css-extract-plugin
export var active = "Styles-module--active--gc3N0";
export var benefit = "Styles-module--benefit--Pvjvi";
export var benefitCont = "Styles-module--benefitCont--LyYme";
export var bg = "Styles-module--bg--OF0zd";
export var blockImage = "Styles-module--blockImage--lA9Bl";
export var blockLink = "Styles-module--blockLink--oqBDF";
export var buttonSpace = "Styles-module--buttonSpace--YsSrK";
export var carouselTitle = "Styles-module--carouselTitle--XkJdV";
export var container = "Styles-module--container--8BxnO";
export var description = "Styles-module--description--pZgk6";
export var diagram = "Styles-module--diagram--eOZEn";
export var editor = "Styles-module--editor--xEG4K";
export var eshopMockup = "Styles-module--eshopMockup--uNxwB";
export var fixSectionTopSpace = "Styles-module--fixSectionTopSpace--cIPwg";
export var fixTopSpace = "Styles-module--fixTopSpace--KuL4r";
export var formatText = "Styles-module--formatText--nkm4j";
export var fullToRight = "Styles-module--fullToRight--to1qI";
export var functions = "Styles-module--functions--jFaZe";
export var header = "Styles-module--header--FYTg0";
export var hpProjects = "Styles-module--hpProjects--XDg-f";
export var iconOut = "Styles-module--iconOut--LIEtq";
export var list = "Styles-module--list--JbTb5";
export var listIcon = "Styles-module--listIcon--4eJU-";
export var mainCont = "Styles-module--mainCont--g-e+h";
export var menu = "Styles-module--menu--ZB544";
export var rotated = "Styles-module--rotated--Dz7ym";
export var sectionMarginBottom = "Styles-module--sectionMarginBottom--n2Vnp";
export var sectionMarginTop = "Styles-module--sectionMarginTop--71L0t";
export var showAllButton = "Styles-module--showAllButton--UNFf6";
export var subtitle = "Styles-module--subtitle--5IdIY";
export var title = "Styles-module--title--9j4KV";
export var topSection = "Styles-module--topSection--FYin5";